<template>
  <div class="home-termos">
    <mf-loading-dialog :loading="$apollo.loading">
      <v-container class="pa-0" fluid>
        <v-card class="my-3 px-6">
          <v-card-title>
            <span class="section-title">Termos</span>
          </v-card-title>
          <v-card-text>
            <v-container fluic class="pa-0">
              <v-row>
                <v-col cols="12" class="mb-0">
                  <label class="detail-title">Termo</label>
                  <mf-text-area-input v-model="loyalty_terms.content" outlined :errors-messages="$v.loyalty_terms.content.$error ? 'Campo obrigatório.' : ''" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="mb-0">
                  <label class="detail-title">Versão</label>
                  <mf-text-input v-model="loyalty_terms.version" class="mb-0" outlined :error-messages="versionError" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-3 pt-0 pr-3">
            <v-spacer />
            <default-terms v-model="terms_dialog" @set-term="setTerms" />
            <mf-action-buttons
              class="mt-2"
              :primary-button="{
                text: 'Atualizar Termos',
                action: updateTerms,
                isVisible: true,
                isDisabled: false,
                isLoading: saving
              }"
              :secondary-button="{
                text: 'Usar termo padrão',
                action: openConfig,
                isVisible: true,
                isLoading: saving
              }"
              :extra-button="{
                text: 'Expandir termo',
                action: expandTerms,
                isVisible: true,
                isDisabled: loyalty_terms.content == '',
                isLoading: saving
              }"
            />
          </v-card-actions>
        </v-card>
        <dialog-term v-model="dialog" title="Termos do conta" :term-content="loyalty_terms.content" :max-width="1800"></dialog-term>
      </v-container>
    </mf-loading-dialog>
  </div>
</template>

<script>
import { QUERY_TERMS_CONFIGURATION, MUTATION_LOYALTY_TERMS } from '@/modules/accounts/graphql'
import { required } from 'vuelidate/lib/validators'
import { notOnlySpace } from '@/helpers/validators.js'
import semver from 'semver'
export default {
  name: 'Terms',
  components: {
    DefaultTerms: () => import('@/modules/accounts/components/hotsite/DefaultTerms.vue'),
    DialogTerm: () => import('./DialogTerm.vue')
  },
  data: () => ({
    saving: false,
    termKey: 0,
    dialog: false,
    terms_dialog: false,
    loyalty_terms: {
      version: '',
      content: ''
    }
  }),
  computed: {
    versionError() {
      if (!this.$v.loyalty_terms.$dirty) return ''
      if (!this.$v.loyalty_terms.version.required) return 'Campo obrigatório.'
      if (!this.$v.loyalty_terms.version.isSemver) return 'Campo deve ter o formato x.y.z.'
      if (!this.$v.loyalty_terms.version.gteSemver) return `Campo deve ser igual ou maior que a versão atual (${this.configuration.loyalty_terms.version})`
      return ''
    }
  },
  apollo: {
    configuration: {
      query: QUERY_TERMS_CONFIGURATION,
      fetchPolicy: 'no-cache',
      variables() {
        return { account_id: this.$route.params.id }
      },
      update({ configuration }) {
        if (!configuration) return {}
        // Set flags
        this.loyalty_terms.content = configuration?.loyalty_terms?.content || ''
        this.loyalty_terms.version = configuration?.loyalty_terms?.version || '0.0.1'
        // Rerender auto-grow
        this.termKey += 1
        return configuration
      },
      notifyOnNetworkStatusChange: true
    }
  },
  mounted() {
    document.title = 'Termos'
  },
  methods: {
    isSemver(value) {
      return Boolean(semver.valid(value))
    },
    setTerms(terms) {
      this.loyalty_terms.content = terms
      this.terms_dialog = false
    },
    expandTerms() {
      this.dialog = true
    },
    openConfig() {
      this.terms_dialog = true
    },
    gteSemver(value) {
      const currentVersion = this.configuration?.loyalty_terms?.version
      return !currentVersion || (Boolean(semver.valid(value)) && semver.gte(value, currentVersion))
    },
    async updateTerms() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$snackbar({ message: 'Configure os campos corretamente.' })
        return
      }
      let confirmMessage
      if (this.loyalty_terms.version === this.configuration?.loyalty_terms?.version) {
        confirmMessage = 'A versão do termo não foi atualizada. Apenas novos clientes irão aceitar o novo termo. Deseja continuar?'
      } else {
        confirmMessage = 'A versão do termo foi alterada. Todos os clientes terão de re-aceitar o termo no aplicativo. Deseja continuar?'
      }
      const confirmation = await this.$confirm({
        title: 'Atualizar termos',
        message: confirmMessage,
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      this.saving = true
      await this.$apollo.mutate({
        mutation: MUTATION_LOYALTY_TERMS,
        variables: {
          account_id: this.$route.params.id,
          content: this.loyalty_terms.content,
          version: this.loyalty_terms.version
        }
      })
      this.saving = false
      this.$apollo.queries.configuration.refresh()
    }
  },
  validations() {
    return {
      loyalty_terms: {
        version: { required, isSemver: this.isSemver, gteSemver: this.gteSemver },
        content: { required, notOnlySpace }
      }
    }
  }
}
</script>
<style lang="scss">
.home-termos {
  .section-title {
    font-size: 20px;
    font-weight: bold;
  }
  .detail-title {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    color: #000000;
    margin-bottom: 8px;
  }
}
</style>
