var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-termos"},[_c('mf-loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"my-3 px-6"},[_c('v-card-title',[_c('span',{staticClass:"section-title"},[_vm._v("Termos")])]),_c('v-card-text',[_c('v-container',{staticClass:"pa-0",attrs:{"fluic":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"detail-title"},[_vm._v("Termo")]),_c('mf-text-area-input',{attrs:{"outlined":"","errors-messages":_vm.$v.loyalty_terms.content.$error ? 'Campo obrigatório.' : ''},model:{value:(_vm.loyalty_terms.content),callback:function ($$v) {_vm.$set(_vm.loyalty_terms, "content", $$v)},expression:"loyalty_terms.content"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"3"}},[_c('label',{staticClass:"detail-title"},[_vm._v("Versão")]),_c('mf-text-input',{staticClass:"mb-0",attrs:{"outlined":"","error-messages":_vm.versionError},model:{value:(_vm.loyalty_terms.version),callback:function ($$v) {_vm.$set(_vm.loyalty_terms, "version", $$v)},expression:"loyalty_terms.version"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-3 pt-0 pr-3"},[_c('v-spacer'),_c('default-terms',{on:{"set-term":_vm.setTerms},model:{value:(_vm.terms_dialog),callback:function ($$v) {_vm.terms_dialog=$$v},expression:"terms_dialog"}}),_c('mf-action-buttons',{staticClass:"mt-2",attrs:{"primary-button":{
              text: 'Atualizar Termos',
              action: _vm.updateTerms,
              isVisible: true,
              isDisabled: false,
              isLoading: _vm.saving
            },"secondary-button":{
              text: 'Usar termo padrão',
              action: _vm.openConfig,
              isVisible: true,
              isLoading: _vm.saving
            },"extra-button":{
              text: 'Expandir termo',
              action: _vm.expandTerms,
              isVisible: true,
              isDisabled: _vm.loyalty_terms.content == '',
              isLoading: _vm.saving
            }}})],1)],1),_c('dialog-term',{attrs:{"title":"Termos do conta","term-content":_vm.loyalty_terms.content,"max-width":1800},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }